import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const ChaseZenger = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Conversation with Chase Zenger</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>Conversation with CX Leader: Chase Zenger</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">March 15, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/atomic-habits"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/chasezenger&text=Conversation+with+Chase+Zenger&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">Did the coronavirus impact your business? Do you wish you could learn how others are weathering the global pandemic? Perhaps you’ve wanted to compare notes with other leaders or be a fly on the wall as they deliberate on how to remain customer obsessed in the most trying of times? Todays’ guest, Chase Zenger has some insights just for you!</p>
                        <p className="py-1">Chase Zenger is a deeply curious thought leader in Customer Experience. As the Chief of Staff to the Chief Customer Officer at Box, the leading Cloud Content Management platform, he oversees many of Box’s efforts to improve customer experience including their Voice of the Customer program.</p>
                        <p className="py-1">Prior to Box, Chase held various positions all with deep ties to Customer Experience including Head of CX at the AI Ad-Tech company Rocket Fuel, Enterprise Customer Success Manger at Qualtrics, and Customer Experience Design Consultant at PwC Advisory. His expertise in identifying, designing, and building ideal customer experiences has helped several Fortune 500 companies improve the way they think about and serve their customers.</p>
                        <p className="py-1"> Our discussion revolved around the impact of Covid 19 and the outlook on customer experience at Box and in general. </p>
                        <Image className="py-3" src="/assets/images/ChaseZenger.jpg" alt="Chase Zenger" fluid/>
                        <h5>How has COVID19 impacted your business?</h5>
                        <p className="pt-3 pb-1">At Box, we are pretty fortunate since we are a tool and solution that helps with remote work and collaboration. We’ve been able to weather the storm and see pretty good momentum in some areas of our business. Internally, it felt like everything was turned upside down with the immediate pivot to remote work. It’s almost been a year and we’re still uncovering the most optimal ways to work.</p>
                        <p className="py-1">We have learned a ton from this experience and have a better perspective on things we did in the past that were either unnecessary or could have been done differently.  A lot of the fat has been trimmed and we have been able to figure out the difference between key projects and busy work.</p>
                        <h5>Have you seen any changes from the customers perspective?</h5>
                        <p className="pt-3 pb-1">First is that our customers expect more from us. For example, there is the demand for more self-service experiences. Customers really value being able to do things on their own, to be able to find answers quickly. We have seen the expectations of our customers change over time. There are some organizations out there providing really great experiences and this has raised the bar for everyone in many ways. And we are looking to match our customers expectations with our product and self-service experiences.</p>
                        <p className="py-1">Second is that we have been able to be more present for our customers.  We’ve ben able to interact with more customers, and have more executive level conversations via zoom. Now that the travel barrier and coordinating in person visits has gone away, there is much more flexibility in our schedules. </p>
                        <p className="py-1">Third is this humanity element that I really love seeing now - both with customers, but also internally with team members and executives.  We see that they have a life outside of work, and this creates a whole different aspect of customer experience. You can connect both on a professional and personal level.  For example, I love the map that you have on the back of your wall, and it sparks a natural conversation point. </p>
                        <h5>Would you say it’s almost easier to get to know one another and be connected via zoom?</h5>
                        <p className="pt-3 pb-1">There is certainly a benefit to going out to dinner and having a broad conversation. I miss that, but at the same time, Zoom conversations allow you to get down to the conversation much faster and is a more efficient use of their time. Also, everyone lets their wall down a bit when they’re at home. You take on the personality of the environment you’re in. Mainly, I think that the pandemic has made us realize that there are things that are more important that business and work. </p>
                        <h5>These changes that you’re seeing, have they changed how you approach customer experience?</h5>
                        <p className="pt-3 pb-1">There is a distinction between changes that we have made to our experience that are here to stay versus those that are adaptations to the current environment and are temporary. </p>
                        <p className="py-1">One of the things we really avoid is to generalize. We know that broadly speaking how we interact with our customers  is  moving more digital, but we want to know what they look like for our specific customers. So, we’ve done a lot of research and customer interviews to understand what the environment and expectations are for our customers and what they need from us to be successful.</p>
                        <p className="py-1">We’ve really doubled down on self-service and providing digital avenues for our customers and providing  education to do things on their own. We’re also focusing on making our product easier to use and adding new features and functionality that enhance how people work remotely. Our fundamental strategy hasn’t changed but how we’ve prioritized and focused our efforts has changed. </p>
                        <h5>What are some changes that aren’t long lasting, and might go back to the way things were?</h5>
                        <p className="pt-3 pb-1">One of the ways we have adapted to the pandemic is to be very empathetic and accommodating of our customers’ financial situations. We want to work with our customers, we value the long-term relationship and despite the challenges they’re going through, we want to keep their business.  Like many SaaS companies during the pandemic, we’ve been very flexible and agile in terms of payments, duration, or license counts with our customers. As the pandemic clears, we anticipate our customers will need less and less of these special measures.</p>
                        <p className="py-1">Another way we have adapted, is to visit our customers via online methods. In the future, I can see us have more of a hybrid approach, but I definitely expect that we will start visiting customers again, since there is no real substitute for in person meetings. Once things are more safe, we will go back to that relatively quickly. </p>    
                        <p className="py-1">Events is another area where we have adapted, and I think we would go back to in-person or hybrid events. I think a lot of companies have learned a lot around the power of virtual and the reach of virtual, and so I do think it’ll be some sort of hybrid combination between virtual and in person.</p>
                        <h5>Can you share some examples of how you have ‘trimmed the fat’?</h5>
                        <p className="pt-3 pb-1">The quantity of emails we all receive has just shot up since covid. And so, we focused on our email communication strategy. We looked at it holistically and tried to be smarter about when and how we reached out to our customers. We wanted to be respectful of our customers, and this would make them more likely to read the ones that we did send.  We have certainly seen improvement there and a lot of gratitude from our customers.</p>
                        <p className="py-1">One of the more tangible things that we did was on our support feedback survey. We trimmed  down the survey from three or four questions, to just one question. We saw an immediate 10% increase in response rate and now have a much larger, more statistically significant data set for us to understand if we met the customers expectations or not. So we recognized that customers are getting tons and tons of surveys and we’d rather make it easier for them to give us feedback.</p>  
                        <h5>What advice would you give to other leaders who are grappling with similar issues are wondering how they might want to tailor their approach to our current situations?</h5>
                        <p className="pt-3 pb-1">Follow your gut, start small and get some quick wins. Focus on areas that will have an outsized benefit and impact.  This will build that momentum and get the snowball effect.</p>
                        <p className="py-1">Continuously get feedback from your customers directly. Don’t assume that whatever is happening broadly in the market is exactly what is happening with your customers. </p> 
                        
                        <p className="pt-3 pb-1">Did Chase’s advice resonate with you? Head on over to <a href="https://www.linkedin.com/in/chase-zenger/">LinkedIn</a>to continue the conversation with Chase.</p>
                        
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default ChaseZenger;
