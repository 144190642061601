import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const CustomerSignals = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>High Confidence Customer Signals</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>High Confidence Customer Signals</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">February 1st, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/customer-signals"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/customer-signals&text=High+Confidence+Customer+Signals&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">What are the customer signals that should give a company the confidence that they are on the right path? Which metric is more important than the others? How can we tell that the business decisions we’re making will have the right customer outcomes that we desire? I have been grappling with these questions for some time now. And of course, an easy answer is “It depends”. It depends on what the outcome is that we are trying to achieve. It depends on who our customers are, what product or service we are selling and so on. But I personally have never found “it depends” to be a very useful answer, so why should I foist it on to you?</p>
                        <p className="py-1">Thus I have been on the lookout for a framework to help with my own decision making. And recently I came across something that piqued my interest and I wanted to explore that with you. The original idea comes from John C Maxwell, who was sharing his thoughts in a podcast with Shane Parrish of Farnam Street.</p>
                        <Image className="py-3" src="/assets/images/signal2.jpg" alt="Lighthouse image" fluid/>
                        <h3>How is confidence gained?</h3>
                        <p className="pt-3 pb-1">Maxwell posits that confidence is initially gained through affirmation. Imagine a student who learns a new math concept. Their parents cheer and affirm their mathematical skills. This could be the student’s first taste of confidence in their computational skills. However, only when that initial affirmation is followed by the student’s continued mathematical success, will that turn to confidence by accomplishment. This, Maxwell holds is the true form of confidence - when that confidence is gained by one’s internal success or accomplishment, and doesn’t rely solely on external validation or affirmations.</p>
                        <h3>Ok… so how does this relate to CX?</h3>
                        <p className="pt-3 pb-1">The distinction between confidence by affirmation and confidence by accomplishment is crucial in business. If all our confidence stems from external sources and we have no sustained accomplishments to cement that confidence, the confidence eventually becomes hollow and without substance. If we’re basing business decisions on signals that have shallow confidence, then sooner or later we are bound to be making suboptimal decisions. So, let’s talk about how we can apply this framework to customer signals and CX. </p>
                       <Image className="py-3" src="/assets/images/signal1.jpg" alt="Beam of light" fluid/>
                        <h2>Confidence by affirmation </h2>
                        <p className="pt-3 pb-1">Confidence by affirmation is gained by external sources or validation. I think of this as customer signals that we can get from outside our company. One example is survey results, where our customers are verbalizing their perception of our products or services. This commonly translates to customer satisfaction (CSAT) or net promoter score (NPS) or even product satisfaction (PSAT). These are metrics and signals that come from outside our company. However, they do give us confidence in our understanding of our customers. </p>
                        <p className="py-1">Another example of external validation can be analyst reports or magic quadrant rankings. This is a gauge of how the analyst community perceives our company and what their independent research is telling us. Although these rankings give companies enormous boosts of confidence, these are still external sources of validation, and thus this confidence is gained by affirmations. </p>
                        <h2>Confidence by accomplishment </h2>
                        <p className="pt-3 pb-1">To achieve the next level in gaining confidence about customer signals we need to consider the perspective of accomplishment. How do we do that? Well, we do that by focusing on what our key stakeholders are <em>doing</em> vs what they are <em>saying</em>. For example, if our customers tell us they love our product and CSAT is through the roof, however they don’t actually buy our product and revenue is shrinking, we aren’t focusing on the right signals. It is very important to focus on the actions stakeholders are performing, vs. only on the words they are verbalizing. By no means am I suggesting that survey results and analyst rankings are unimportant - what I am saying is that they are useless unless we layer that information with the actual behavior of our stakeholders. </p>
                        <ul className="pb-3">
                            <li><em>Customers</em> This is obviously a key stakeholder group for any business. In order to truly understand how our customers feel about our product, we need to study their usage of it. Are they using us more now than in the past? Are we a critical part of their infrastructure? Are we involved in their mission critical endeavors? Is the customer spending indicating that they are allocating a greater portion of their wallet to us? The answers to these questions will give us the confidence that we are on the right path to provide a product /service and an experience that is truly valuable to our customers.</li>
                            <li><em>Employees</em> This is another (often overlooked) key stakeholder group for any business. And this is another place to gauge the accomplishments of the business. Are the employees engaged? Are they feeling accomplished? Are they feeling empowered to serve customers appropriately? Are they doing the best work of their life? The answers to these questions will ensure that we have created the best environment to serve our customers in the ways that they deserve. </li>
                        </ul>
                        <p className="pt-3 pb-1">So where are you in the confidence spectrum? Are all your customer signals coming from affirmation, or do you have the ability to interpret the signals coming from your internal accomplishments as well? If you are utilizing both, where are you placing your focus?</p>
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default CustomerSignals;
