import React from "react";

import { BrowserRouter as Router, Route } from 'react-router-dom';

import './fonts.css';

import TopNav from './components/TopNav.js';
import Home from './pages/Home';
import BuildingBlocks from './pages/BuildingBlocks';
import Silos from './pages/Silos';
import Blueprint from './pages/Blueprint';
import Strategy from './pages/Strategy';
import CustomerSignals from './pages/CustomerSignals';
import AtomicHabits from './pages/AtomicHabits';
import MetricsTips from './pages/MetricsTips';
import CXKPIs from './pages/CXKPIs';
import JourneyMapping from './pages/JourneyMapping';
import Antifragile from './pages/Antifragile';
import Transparency from "./pages/Transparency";
import ChaseZenger from "./pages/ChaseZenger";
import WhyBlog from "./pages/WhyBlog";
import DiversityInclusion from "./pages/DiversityInclusion";

const App = () => {

  return (
    <Router>
      <div>
          <TopNav />
          <Route exact path="/" component={Home} />
          <Route exact path="/blog" component={Home} />
          <Route exact path="/about" component={Home} />
          <Route exact path="/3-building-blocks" component={BuildingBlocks} />
          <Route exact path="/silos" component={Silos} />
          <Route exact path="/blueprint" component={Blueprint} />
          <Route exact path="/stuck-on-strategy" component={Strategy} />
          <Route exact path="/customer-signals" component={CustomerSignals} />
          <Route exact path="/atomic-habits" component={AtomicHabits} />
          <Route exact path="/9-tips-metrics" component={MetricsTips} />
          <Route exact path="/CX-KPIs" component={CXKPIs} />
          <Route exact path="/Journey-Mapping" component={JourneyMapping} />
          <Route exact path="/Antifragile" component={Antifragile} />
          <Route exact path="/transparency-trust" component={Transparency} />
          <Route exact path="/chasezenger" component={ChaseZenger} />
          <Route exact path="/Why-Blog" component={WhyBlog} />
          <Route exact path="/Diversity-Inclusion" component={DiversityInclusion} />
      </div>
    </Router>
  )
}

export default App;
