import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const WhyBlog = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Why do I blog</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>Why do I blog?</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">March 22, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/atomic-habits"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/Why-Blog&text=Why+do+I+blog&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">A few of you have recently asked me: Why do you write a blog? As I sat down to think about it, I realized that I created my blog for 3 main reasons. </p>
                        <Image className="py-3" src="/assets/images/WhyBlog1.png" alt="Why blog" fluid/>
                        <h3>To give back to the community</h3>
                        <p className="pt-3 pb-1">When I first chose to make a career pivot to customer experience, I had a steep learning curve. I wasn’t used to the language, the methods, or the concepts of CX. And through attending countless webinars and reading hundreds of articles and many books, I came to a realization. There was a lot of generic advice out there and most of the people giving the advice wanted to sell me their services in return or for more specific guidance. I had to search high and low for actionable insights that I could adopt in my practice right away. </p>
                        <p className="py-1">So, once I felt like I had some learnings, I wanted to share them. Sharing my lived experiences in bite sized tips and tricks is my way of giving back to the community. If this blog helps even one person as they are navigating their career in CX, I will have achieved the outcome I set out to achieve.</p>
                        <h3>To understand my own craft better</h3>
                        <h5>‘Teaching is the highest form of understanding.’ –Aristotle</h5>
                        <p className="pt-3 pb-1">I knew that in order to write about CX concepts and to teach others, I would have to understand the concepts deeply myself. I have researched and talked to professionals and expanded my own circle of knowledge in order to bring value to my readers.</p>
                        <p className="py-1">I am on a lifelong journey of learning and improving I knew that deepening my own knowledge and understanding would not only help me as I navigate building the CX function, but would help my readers too.</p>                 
                        <Image className="py-3" src="/assets/images/WhyBlog2.png" alt="Why I blog" fluid/>
                        <h3>To hold myself accountable</h3>
                        <p className="pt-3 pb-1">I have been very influenced by the James Clear book, Atomic Habits. <a href="https://www.nextbonnie.com/atomic-habits"> I have even written about it</a>. In 2020, I built up some really good habits such as exercise and self care. So in 2021, I decided to build a habit of writing. It made sense to write a blog for the reasons I’ve already covered in this post. So, I made a commitment to myself to write a blog post every week for the year 2021. </p>
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default WhyBlog;
