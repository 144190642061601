import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const Transparency = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Transparency and Trust</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>6 Questions that build Trust</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">March 15, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/atomic-habits"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/transparency-trust&text=Transparency+and+Trust&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">Consumers are loyal to brands they can trust. One of the key ways to earn the trust of consumers is to be transparent about our brand and business practices. For large, publicly traded companies, there are vehicles such as 10K filings that are meant to encourage transparency.</p>
                        <p className="py-1">But what about the rest of us who might be small to medium sized private companies? How can we determine what sort of transparency our customers are seeking? There are 6 questions you can ask that will help you determine the type of information your customers are likely to care the most about: These are the <a href="https://k12.thoughtfullearning.com/minilesson/asking-and-answering-5-ws-and-h-questions">five W's and one H</a> questions.</p>

                        <Image className="py-3" src="/assets/images/transparency1.jpg" alt="transparency" fluid/>
                        <p className="py-1">Image courtesy of: <a href="https://www.hrinasia.com/employee-relations/embracing-trust-and-transparency-at-workplace/">Embracing Trust and Transparency at Workplace - HR in ASIA</a>.</p>
                        
                        <ul className="pb-3">
                        <li>WHO is our most important audience?</li>
                        <li>WHY have they come to us?</li>
                        <li>WHAT is are they looking for?</li>
                        <li>WHEN would they desire this information?</li>
                        <li>WHERE would they like this information surfaced?</li>
                        <li>HOW would they like this information delivered?</li>
                        </ul>
                        <p className="pt-3 pb-1">As we break down this question, let’s approach this from a B2B perspective and think of the end user and decision maker.  To further narrow down our thinking, let’s imagine we’re dealing with a SaaS offering.</p>
                        <h3>The User</h3>
                        <p className="pt-3 pb-1">Depending on the product you’re offering, a broad range of personas fit within the “User” or “End user” category. Any person who uses your product for their day to day work  or life can considered a user for the purpose of this exercise. This can be a developer, product manager, systems administrator, salesperson or even a maintainer.</p>
                        <p className="py-1">The User generally has 2 basic needs - for your product to be <em>reliable</em> and for product to be <em>easy to use</em>. Your user would want transparency around these two needs in a timely manner, and within the context of their work. </p>
                        <h4>Reliability:</h4>
                        <p className="pt-3 pb-1">Think about how reliability is perceived in the context of your product. Is it uptime? Is it the availability of all features? Is it the reassurance of the safety of their data? </p>
                        <p className="py-1">In order to be transparent about how reliable and stable your product is, you could share information about uptime and any service disruptions. Sharing this information consistently is the gold standard in many companies.  Here are some examples you can peruse.</p> 
                        <ul className="pb-3">
                        <li><a href="https://github.blog/2021-03-03-github-availability-report-february-2021/">GitHub Availability Report: February 20201</a></li> 
                        <li><a href="https://status.aws.amazon.com/">Amazon Health Dashboard</a></li> 
                        <li><a href="https://status.cloud.google.com/incident/cloud-networking/19009#:~:text=On%20Sunday%202%20June%2C%202019,and%204%20hours%2025%20minutes.">Google Cloud Platform: Incident Report</a></li> 
                        </ul>            
                        <Image className="py-3" src="/assets/images/transparency2.png" alt="aws health dashboard" fluid/>
                        <p className="pt-3 pb-1">Other ways of being transparent would include being proactive in communicating any bug fixes or feature releases that relate to your reliability. Another approach would be to proactively share key areas of investments (datacenter, re-engineering, AI/ML etc) that your company is making in order to boost reliability.</p>
                        <h4>Ease of Use:</h4>
                        <p className="pt-3 pb-1">Updated, easy to find and useful product documentation is a critical way to make your product easy to use. Continue to think of ways to bring your documentation closer to where your users are. Another great practice is to measure the efficiency of the documentation - are the users able to solve their problems using your documentation? It is important to ask the users and not make assumptions in this regard.</p>
                        <p className="py-1">Your users are also eager to understand what features are being released that will enhance the particular job they are looking to complete. It is equally important to consider ways to proactively alert the users about any existing bugs that are relevant to them.</p>    
                        <p className="py-1">Your users often take the time to convey their desired features or bugs they have encountered through various channels such as tickets, emails or conversations with their account team. Proactively informing users about the status of their request is a step in the right direction towards building transparency.</p>
                        <h3>The Decision Maker</h3>
                         <p className="pt-3 pb-1">Depending on the product you’re offering, a broad range of personas fit within the “Buyer” or “Decision Maker” category. A few personas, such as Manager, Director or CxO could fit within the “ Decision Maker” category. Remember that a Decision Maker can also be any person who makes or highly influences the decision to purchase your product.</p>
                        <p className="py-1">The Decision Maker generally has 2 basic needs - confidence that the future direction of your product continues to make it the right choice for their organization and to measure the impact your product has on their business outcomes. The Decision Maker would want transparency around these two needs in a timely manner, and within the context of their work.</p>  
                        <h4>Confidence in Platform:</h4>
                        <p className="pt-3 pb-1">As the Decision Maker is looking to plan their yearly OKRs, they want to know that your company’s direction maps to their strategy. For this, they need visibility into your roadmap and your big investments (such as new product lines, new vertical, new features etc). A best practice is to make your roadmap publicly available, easy to consume and make sure to keep it regularly updated.</p>
                        <p className="py-1">Success stories from peers, especially those with some contextual affinity such as the same industry or geography, can be very conducive in building confidence. Thus, it is important to be proactive and structured about sharing success stories.</p> 
                        <h4>Visibility into Outcomes:</h4>
                        <p className="pt-3 pb-1">It is important to help your Decision Makers to determine progress towards their goals. It is up to you to make it easy to measure outcomes that you are helping them to achieve. In addition, consider providing this information in a format that is easy to consume - such as a downloadable report, or an easily accessible dashboard.</p>
                        <h3>Conclusion</h3>
                        <p className="pt-3 pb-1">The efforts listed in this document are the initial steps that a company can take towards greater transparency. As the company matures in this regard, a next step can be to consider greater transparency around data policies and DI&B policies. </p>
                        <p className="py-1">Does your company practice transparency with your customers? What are some key approaches that you take? </p> 
                        <hr />
                        <h5>Further Reading:</h5> 
                        <ul className="pb-3">
                            <li><a href="https://www.themyersbriggs.com/en-US/Connect-with-us/Blog/2020/March/Trust-and-Transparency">Building Trust through Transparency: The Myer-Briggs Company</a></li>
                            <li><a href="https://www.forbes.com/sites/mikekappel/2019/04/03/transparency-in-business-5-ways-to-build-trust/?sh=53673bdd6149">Transparency in Business: Forbes</a></li>
                        </ul>
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default Transparency;
