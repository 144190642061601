import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SideBar from '../components/SideBar';

const Home = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row>
                    <Col xs={12} md={8} className="px-3">
                    <div className="homearticle mb-5">
                            <p className="topic">Diversity and Inclusion</p>
                            <h2>Diversity and Inclusion in Customer Experience</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/diandb1.jpeg" />
                            <p className="py-3 mb-0">Can you incorporate the principles of diversity and inclusion into your CX practice?</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/Diversity-Inclusion">Read more {'>'}</Button>
                        </div>
                    <div className="homearticle mb-5">
                            <p className="topic">Leadership</p>
                            <h2>Why do I Blog?</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/WhyBlog1.png" />
                            <p className="py-3 mb-0">Three reasons why I started my blog.</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/Why-Blog">Read more {'>'}</Button>
                        </div>
                        <div className="homearticle mb-5">
                            <p className="topic">Discussions</p>
                            <h2>Conversation with CX Leader: Chase Zenger</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/ChaseZenger.jpg" />
                            <p className="py-3 mb-0">Learn how Box is navigating the pandemic and improving their customers' experience.</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/chasezenger">Read more {'>'}</Button>
                        </div>
                        <div className="homearticle mb-5">
                            <p className="topic">Leadership</p>
                            <h2>6 Questions to build Trust</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/transparency1.jpg" />
                            <p className="py-3 mb-0">Learn how transparency can help you to build trust with your customers.</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/transparency-trust">Read more {'>'}</Button>
                        </div>
                        <div className="homearticle mb-5">
                            <p className="topic">Book Review</p>
                            <h2>Is your Customer Experience Antifragile?</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/antifragile1.jpg" />
                            <p className="py-3 mb-0">Learn how to make your business antifragile.</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/Antifragile">Read more {'>'}</Button>
                        </div>
                        <div className="homearticle mb-5">
                            <p className="topic">Essentials</p>
                            <h2>Will Journey Maps lead to your Destination?</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/mckinsey-journeymap.png" />
                            <p className="py-3 mb-0">Learn what not to do when it comes to customer journey mapping.</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/Journey-Mapping">Read more {'>'}</Button>
                        </div>
                        <div className="homearticle mb-5">
                            <p className="topic">Measurements</p>
                            <h2>Measuring Customer Experience with 10 Popular KPIs</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/KPI1.png" />
                            <p className="py-3 mb-0">How do you measure your Customers’ Experience and the value of it for your organization?</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/CX-KPIs">Read more {'>'}</Button>
                        </div>
                        <div className="homearticle mb-5">
                            <p className="topic">Measurements</p>
                            <h2>9 Tips for Measuring Customer Experience</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/cjmMeasurements.png" />
                            <p className="py-3 mb-0">Learn how to make the most of your CX measurements</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/9-tips-metrics">Read more {'>'}</Button>
                        </div>
                            <div className="homearticle mb-5">
                            <p className="topic">Essentials</p>
                            <h2>High Confidence Customer Signals</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/signal2.jpg" />
                            <p className="py-3 mb-0">Avoid mistakes when it comes to your measurements</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/customer-signals">Read more {'>'}</Button>
                        </div>
                            <div className="homearticle mb-5">
                            <p className="topic">Book Review</p>
                            <h2>Atomic CX Habits</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/AtomicHabits_1.png" />
                            <p className="py-3 mb-0">Immensely successful "Atomic Habits" applied to your CX discipline</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/atomic-habits">Read more {'>'}</Button>
                        </div>
                        <div className="homearticle mb-5">
                            <p className="topic">Leadership</p>
                            <h2>Stuck on CX Strategy?</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/stuckonstrategy1.png" />
                            <p className="py-3 mb-0">CX strategy made approachable in 3 steps</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/stuck-on-strategy">Read more {'>'}</Button>
                        </div>
                        <div className="homearticle mb-5">
                            <p className="topic">Essentials</p>
                            <h2>3 building blocks of a CX Program</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/CompetitiveCEO.png" />
                            <p className="py-3 mb-0">Begin your CX journey with the 3 I's</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/3-building-blocks">Read more {'>'}</Button>
                        </div>
                        <div className="homearticle mb-5">
                            <p className="topic">Interviews</p>
                            <h2>Customer Experience Blueprint</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/blueprint.jpeg" />
                            <p className="py-3 mb-0">What 16 CX leaders had to say about building a roadmap and setting priorities</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/blueprint">Read more {'>'}</Button>
                        </div>
                        <div className="homearticle mb-5">
                            <p className="topic">Leadership</p>
                            <h2>A CX Leader's Invisible Nemesis</h2>
                            <p className="byline">By <span className="author">Bonnie Chatterjee</span></p>
                            <img className="cardimg" alt="" src="/assets/images/silos.jpg" />
                            <p className="py-3 mb-0">How silos are inhibiting the success of your CX program and what to do about it</p>
                            <Button className="linkbutton pb-3 pl-0" variant="link" href="/silos">Read more {'>'}</Button>
                        </div>
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default Home;
