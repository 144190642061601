import React from 'react';

import Col from 'react-bootstrap/Col';

import Subscribe from '../components/Subscribe';

const SideBar = () => {
    
    return (
        <Col xs={12} md={4} className="pl-3">
            <div className="aboutcard mb-4">
                <img className="cardimg" alt="" src="/assets/images/bonniec.jpg" />
                <h5 className="author p-2 mt-3 mb-0">Bonnie Chatterjee</h5>
                <p className="px-4 pb-4">Hi, I’m Bonnie, and I have spent 20 years in the Silicon Valley building businesses and teams. In this blog I share my research and learnings, cutting through the fluff to bring you actionable insights and practical tips you can apply today.</p>
            </div>
            <Subscribe />
            <div className="coffeecard mb-4">
                <h5 className="py-4">Let's grab coffee</h5>
                <p className="pb-4">
                    <a className="socialbutton px-3" href="https://www.linkedin.com/in/barnalic/"><i className="fab fa-linkedin"></i></a>
                    <a className="socialbutton px-3" href="https://twitter.com/barnali_c"><i className="fab fa-twitter-square"></i></a>
                </p>
            </div>
        </Col>
    )
}

export default SideBar;