import React from 'react';
import MetaTags from 'react-meta-tags';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const Antifragile = () => {
    
    //let history = useHistory();

    return (
        <div>
            <MetaTags>
                <meta property="og:image" content="https://www.nextbonnie.com/assets/images/antifragile1.jpg"/>
                <meta property="og:image:url" content="https://www.nextbonnie.com/assets/images/antifragile1.jpg"/>
                <meta name="twitter:image" content="https://www.nextbonnie.com/assets/images/antifragile1.jpg"/>
                <meta property="og:url" content="https://www.nextbonnie.com/Antifragile"/>
            </MetaTags>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Is your Customer Experience Antifragile?</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>Is your Customer Experience Antifragile?</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">March 1st, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/customer-signals"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/Antifragile&text=Is+Your+Customer+Experience+Antifragile+?s&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">Antifragile has made a huge impression on me. The very concept of seeking chaos and discomfort, as a vector for strength and growth is so fascinating. It seems like an obvious principle when it comes to physical strength - you work out, thereby tearing down your muscles and then they grow back stronger. But how would you apply that to you mental fortitude? And the topic of today’s post - how would you apply that to your business?</p>
                        <h3>Key Concept: Antifragile</h3>
                        <p className="py-1">Taleb talks about three systems - the fragile, the robust and the anti fragile. We are familiar with fragility - systems that break easily. And we generally harbor aspirations toward robustness and resilience. But Taleb asks us to consider the antifragile - systems that absorb stressors and grow stronger from it. </p>
                        <h4>"Antifragility is beyond resilience or robustness. The resilient resists shocks and stays the same; the antifragile gets better.” ~ Nassim Nicolas Taleb</h4>
                        <p className="pt-3 pb-1">I am fascinated by the concept of anti fragility. To me, it is truly an ideal system - one that thrives through challenges, change and chaos and emerges stronger for it. I have been thinking about how to build a business that is truly antifragile, one that will grow stronger every time it is stressed,  one that thrives in volatility. And I have been thinking about how I can apply that to growing a CX discipline. </p>
                        <Image className="py-3" src="/assets/images/Antifragile2.jpeg" alt="Antifragile image" fluid/>
                        <p className="pt-3 pb-1">Here are 3 key concepts from Taleb’s book that resonate with me, and my ideas on how to incorporate it into your business. </p>
                        <h3>Optionality</h3>
                        <p className="pt-3 pb-1">We know that in life and in business it is important to have options. When challenging situations arise, having options offers a higher probability of emerging out of it unscathed or stronger.  If you lost your job, having a solid bank balance, or a side income, or a group of generous friends increases the likelihood of you getting back on your feet quickly. Taleb further suggests that the availability of options where the likelihood of upsides is asymmetrical (higher) to those of downsides is a vector of antifragility. </p>
                        <h4>How does this relate to CX?</h4>
                        <p className="pt-3 pb-1">Let’s say we are trying to solve the problem of low customer satisfaction. We can opt to :</p>
                        <ul className="pb-3">
                            <li>get new features to market faster, or </li>
                            <li>increase our ticket resolution time, or </li>
                            <li>improve our customer success practices. </li>
                        </ul>
                        <p className="pt-3 pb-1">Thus we have multiple options. In addition, we can consider asymmetry.  For the new features we are building, we have the engineering costs and opportunity costs on one had; on the other hand we have customer expectations. We could be building a feature that is not ultimately as popular as we might expect, but can stack the cards asymmetrically in our favor by picking the features that were most requested by customers.</p>
                        <h3>Rational Flaneur </h3>
                        <p className="pt-3 pb-1">“The rational flaneur is someone who makes a decision at every step to revise his schedule, so that he can imbibe things based on new information.” Taleb further posits that “the flaneur is not a prisoner of a plan.”  Being able to remain nimble, use learnings from one stage to improve the next - this sounds like business 101, doesn’t it?</p>
                        <h4>How does this relate to CX?</h4>
                        <p className="pt-3 pb-1">For us CX leaders, it is important to find ways to remain nimble. And to make changes iteratively, always using information from previous cycles to improve our efforts. </p>
                        <p className="py-1">Let’s use our example of low customer satisfaction again. Let’s say we have decided to build a new feature that was the most requested one from customers. As we are building that feature, a related bug has come to light. Not many customers have noticed it yet, but it is rather dangerous and might leave our customers vulnerable. In this situation, being able to pause, assess the new risk and be able to pivot based on the gathered information would help the business become more antifragile. </p>
                        <Image className="py-3" src="/assets/images/antifragile1.jpg" alt="antifragile image" fluid/>
                        <h3>Green Lumber Fallacy</h3>
                        <p className="pt-3 pb-1">Taleb tells the story of a very successful green lumber trader. Green lumber is basically freshly cut lumber that hasn’t dried yet. This trader thought that this lumber was painted green. Although this trader didn’t know a fundamental piece of information about this product, he was an expert in many other areas that helped him to become so successful in his trade.  Taleb defines the green lumber fallacy as “the situation in which one mistakes a source of necessary knowledge for another, less visible from the outside, less tractable, less narratable. “</p>
                        <h4>How does this relate to CX?</h4>
                        <p className="pt-3 pb-1">Let’s go back to our example of low CSAT.  Now the teams have pivoted to solving that critical bug that wasn’t part of our initial plan.  As a CX leader you are fretting because the feature that customers had requested heavily has now been delayed significantly.  Is it possible that you are falling into the green lumber trap? Is is possible that you are so focused on what the customers are ‘saying’ that you haven’t paid enough attention to what they are ’doing’? Is it possible that this bug actually impacts that single most used feature in your product and therefore a bug in that feature would lead to a catastrophic erosion in customer trust?</p>
                        <p className="py-1">As a CX leader it is important to ensure that we are paying attention to both the obvious and the non-obvious signals that our customers are sending us.  Where are you in practicing these principles? Are you building in optionality, navigating your strategy nimbly, like a rational flaneur, and are you ensuring that you aren’t falling victim to the green lumber fallacy?</p>
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default Antifragile;
