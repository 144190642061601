import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const Strategy = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Stuck on your CX Strategy?</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>Stuck on your CX Strategy?</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">January 18, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/stuck-on-strategy"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/stuck-on-strategy&text=Stuck+on+CX+Strategy&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">Are you stuck trying to figure out your CX Strategy? Do you have your leadership team asking for your guidance, but you aren’t sure where to start? Are you wondering what strategy even means? Today, I bring you some answers to these questions.</p>
                        <p className="py-1">Before we delve into that however, we will need to understand what “strategy” is and how it plays a role in business success.</p>
                        <h3>What is a Strategy?</h3>
                        <p className="pt-3 pb-1">The term “strategy” is derived from the Greek word <em>strategia</em> which refers to generalship in battle. It is not known how this term became ubiquitous in the modern workplace, but, there is certainly a lot of confusion about this term today. In essence, a strategy is a set of guiding principles that creates a framework for decision making within a company. It is the leadership team’s role to create clarity around the strategy, ensure that it is well communicated and adopted, and to make sure that it yields the type of decision making, especially around prioritization, that is desired.</p>
                        <p className="py-1">So, if a strategy is really a set of guiding principles and a framework, what is is <em>not</em>? A strategy is not a <em>vision</em>, which is an inspiring view of what it will look like for the organization to have achieved its goals. A strategy is not a <em>mission</em>, which is what the organization wants to accomplish. Finally a strategy is not the same as <em>goals</em>, which are specific milestones that an organization wants to achieve. Goals are often known as <em>objectives</em> in some organizations and the specific measurements are known as <em>key results</em>.  So if we are clear about why we are doing something (our vision), what we want to achieve (our mission, goals, objectives, etc.), <em>strategy</em> tells us the <b>how</b>.</p>
                        <Image className="py-3" src="/assets/images/stuckonstrategy1.png" alt="" fluid/>
                        <h3>What is a CX Strategy?</h3>
                        <p className="pt-3 pb-1">Now that we have established what strategy is, let us put that in context of Customer Experience. A CX Strategy will help us achieve our business goals while keeping our customer front and center.  So let’s look at some examples. A company could have a goal to dominate the market. A financial strategy to get there could be to grow more revenue; an operational strategy to get there could be provide stellar service; a product strategy could be to have a lightning fast product. As a CX leader, as you’re crafting your CX strategy, think about how your strategy will align with and further the company’s overarching goals.</p>
                        <p className="py-1">In interviewing many CX leaders across America and globally, I have found that CX strategies generally have 3 components to be to be truly successful. Let’s see how these components play out with one of the examples we discussed above - growing more revenue.</p>
                        <h3>Understanding the problem:</h3>
                        <p className="pt-3 pb-1">In order to grow revenue, we must first understand the problem at hand.  Is our revenue not growing today? Is the growth rate increasing or decreasing? To truly understand this problem, we will need to understand our customers. The CX leader should ask:</p>
                        <ul className="pb-3">
                            <li>Who is spending with us?</li>
                            <li>Why are they spending with us?</li>
                            <li>What factors determine the portion of their wallet they spend with us?</li>
                            <li>And finally - what would influence those factors, and thus the behavior of our customers?</li>  
                        </ul>
                        <p className="py-1">Being able to answer these questions would unlock the company’s potential to maximize their revenue while keeping their customer close to them. Thus it is really important to understand the problem from a specific customer-focused lens.</p>
                        <h3>Measuring the key pieces:</h3>
                        <p className="pt-3 pb-1">Conventional wisdom suggests that we should measure what we want to improve. Thus surely, any strategy of a CX leader should include a disciplined focus on measurement. Taking the strategy of growing revenue, you could think about measuring the growth rates over time, or which segment of the customers are behaving in ways that deviate from the norm, thus giving you valuable insights. You could also think about measuring the sentiment of your customers and think through what that is telling you about your product and hence determine the correlation between sentiment and revenue. Regardless of the path you wish you pursue, I cannot overstate the importance of measurement as a cornerstone of your CX strategy.</p>
                        <Image className="py-3" src="/assets/images/stuckonstrategy2.png" alt="" fluid/>
                        <h3>Democratizing the insights:</h3>
                        <p className="pt-3 pb-1">Change doesn’t happen in a vacuum. And the kind of cultural change that CX leaders and practitioners aspire to bring to bear isn’t a solo sport. We have to think about bringing the entire company on the journey with us. And in order to do so, we need to ensure that all the relevant stakeholders have the information and insights they need to execute on the necessary changes. Let’s go back to the example of growing revenue. Let’s say, we have understood that receiving immediate value from our product is a key element that drives the customers behavior of repurchase. Let’s then say we have measured the time our customers are willing to allow before they need to perceive the value from our product, and it is 30 days. This goes to follow that our company has 30 days to show the magic our product can weave in our customer’s life. However, does this crucial piece of information have any value if it is not internalized and acted upon by our customer success teams? I would wager to say that it does not. And therefore, democratizing the insights that we glean are so important and should be a key component of every CX leader’s strategy. </p>
                        <p className="py-1">As you think about your own strategy and the goals you’re setting up for the new calendar or fiscal year, ask yourself - are they aligned with my company’s overarching goals? And let me know if the 3 components of understanding, measuring and democratizing resonate with you.</p>
                        <hr />
                        <h5>Cited:</h5> 
                        <ul className="pb-3">
                            <li><a href="https://www.forbes.com/sites/annlatham/2017/10/29/what-the-heck-is-a-strategy-anyway/#4c70e9597ed8">What The Heck Is A Strategy Anyway?</a></li>
                            <li><a href="https://hbr.org/2007/09/demystifying-strategy-the-what">Demystifying Strategy: The What, Who, How, and Why</a></li>
                            <li><a href="https://go.forrester.com/blogs/so-what-is-cx-strategy-anyway/">So What Is CX Strategy Anyway?</a></li>
                            <li><a href="https://www.superoffice.com/blog/customer-experience-strategy/">7 Ways to Create a Customer Experience Strategy</a></li>
                        </ul>
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default Strategy;
