import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const AtomicHabits = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Atomic CX Habits</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>Atomic CX Habits</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">January 25, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/atomic-habits"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/atomic-habits&text=Atomic CX Habits&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">Atomic Habits is a bestselling book by James Clear about how we can make tiny changes to our everyday lives in order to achieve huge cumulative gains. He walks his readers through a very clear plan to create and maintain good habits. As I was reading it, I started thinking if some of his principles can be applied to a company looking to become more customer-centric. Would Clear’s laws apply to Customer Experience? I decided to find out.</p>
                        <Image className="py-3" src="/assets/images/AtomicHabits_1.png" alt="Atomic Habits by James Clear" fluid/>
                        <h3>First Law: Make it obvious</h3>
                        <p className="pt-3 pb-1">Clear asserts that the process of behavior change always starts with awareness. In order to change existing habits, one has to be aware of them.  This is very relevant in the CX world. Awareness is key! If we aren’t aware of what our customer thinks of our products and our services, how will we know where we need to improve?</p>
                        <p className="py-1">Atomic habits talks about a “Habit scorecard” as well. This is a simple exercise to list all the habits that you currently have and assign positive, negative or neutral characterization to each. The goal then would be to minimize the negative habits, and maximize the positive habits over time. This would translate really well to customer experience as well. I suggest you list out all the areas where you want feedback from your customers. Would you like your customers to rate your overall experience? Would you like to know how they experience your product? How about the interactions with your personnel or your publications? They are all important to measure. List out all the areas that you would like to measure and determine what scale you would like to use. For example, you might want to understand which measurements exist, and where you still need data. Or, you might want to record which scores are subpar and where you would like to see improvement. And just like that you have recorded your own scorecard for CX.</p>
                        <h3>Second Law: Make it attractive</h3>
                        <p className="pt-3 pb-1">There is an important lesson I took away- the more attractive an opportunity is, the more likely it is to be habit-forming. So, now that we have created our CX scorecard, how do we make it attractive to our colleagues and our leadership team? Well, we do that by analyzing the data, of course!</p>
                        <p className="py-1">Once we have a sense of the depth and breadth of the data we have, it is time to review and analyze that data to understand what our customers are telling us. Let’s say you shipped an amazing feature recently, and instead of adoption among 50% of your users, per your expectation, you find that only 10% have adopted it. There is immense opportunity here. Your customers are providing their feedback by not adopting your newly shipped feature. You can either delve into your data about usability of that feature, or talk to customers, or track your support tickets to understand what is behind the lower adoption rates. Understanding and analyzing data and surfacing it in a user friendly way to the rest of the company makes that data attractive. And thus awareness moves from the CX team and becomes more mainstream in the company. And this helps the company make progress on the continuum of customer centricity.</p>                 
                        <Image className="py-3" src="/assets/images/Atomic+Habits2.jpeg" alt="Visual representation of Atomic Habits" fluid/>
                        <h3>Third Law: Make it easy</h3>
                        <p className="pt-3 pb-1">The most effective form of learning is practice, not planning. Clear states that our focus should be on taking action, and not just staying in motion. This is a very important point for CX leaders to note. If we want to improve the customer’s experience, the CX function needs to drive actionable insights that spawn change. Doing surveys ad nauseam is not the ticket. But rather, analyzing that data, serving up insights and driving action based on that insights in the holy grail we are after.</p>
                        <p className="py-1">Basically in order to move a company towards greater customer centricity, we need to ensure that CX improvements are easy to achieve. And the way to make improvements easy is to surface the right insights to the right teams. If you are speaking to a Support team, and sharing insights that the customers really enjoy the POC process with the company’s sales engineers, it might not be as interesting to them. However, if you are sharing with Support that the customer effort score of the latest product that is about the hit the market is pretty high, they will find that interesting, since it will indicate to them that they might get a deluge of tickets in the near future. Thus serving up actionable insights, to the appropriate parties is the best way to make CX easy and accessible for the whole company.</p>    
                         <h3>Fourth Law: Make it satisfying</h3>
                         <p className="pt-3 pb-1">To get a habit to stick you need to feel immediately successful, even if it is in a small way. Clear goes on to state that the cardinal rule of behavior change is <em>What is immediately rewarded is repeated.</em> So, you have created your CX scorecard, you have analyzed and democratized your data, and you have served up actionable insights to the appropriate parties. How do you now make these teams successful? How do you ensure that they feel rewarded for taking on these CX improvements?</p>
                        <p className="py-1">The answer lies in measurement. One key way to prove success is to measure the impact. Taking our previous examples, if your product team has taken steps to improve the usability of your new feature, you can measure ongoing adoption to see in the needle moved upwards from the 10% it was originally stuck at. Or if your support team has staffed up and trained resources, and you find that inspire of the new product with the high effort score, response times haven’t shifted, then you have an immediate win on your hands. Showing and demonstrating these wins ensures that these teams will have the interest and motivation to work on further CX initiatives. This measurement also demonstrates the impact of CX and helps to continue funding of the function itself.</p>  
                        <p className="py-1">
So I discovered that the 4 laws of creating habits was very relevant and applicable to the world of Customer Experience and can be used as a recipe to create a successful CX function. </p> 
                        <hr />
                        <h5>Cited:</h5> 
                        <ul className="pb-3">
                            <li><a href="https://www.visualsynopsis.com/full-collection/atomic-habits-james-clear-visual-synopsis-dani-saveker?rq=atomic%20habits">Photograph from Visual Synopsis by Dani Saveker</a></li>
                        </ul>
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default AtomicHabits;
