import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const JourneyMapping = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Will Journey Maps lead to your Destination?</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>Will Journey Maps lead to your Destination?</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">February 22, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/Journey-Mapping"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/Journey-Mapping&text=Will+Journey+Maps+Lead+You+to+your+Destination?&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">Customer journey maps have achieved buzzword status in the customer experience world. A lot of the CX leaders I know are working on some aspect of journey mapping. But the question I want to explore is: does this map lead you to your destination?</p>
                        <p className="py-1">Before we go any further, let’s talk about our destination. As a CX leader or practitioner, we are always looking to improve our customer’s experience. However, this is similar to entering the name of a city or a state on Google Maps. We will likely end up at the state’s capital or one of the downtown areas of a city. Is that specific enough? Is that what we wanted? Or did we want to find our way to that yummy restaurant we had tried last year? </p>
                        <h3>Purpose of a Journey Map</h3>
                        <p className="pt-3 pb-1">The purpose of a journey mapping exercise should be very specific and clear from the start. Without a clearly defined objective, the whole exercise is likely to yield shelf ware, but no customer-centric results. Here are some ways you can narrow your scope:</p>
                        <ul className="pb-3">
                            <li><b>Consider a specific persona:</b> This is a representation of customer group you want to target.</li>
                            <li><b>Consider a specific product:</b> If you have several products that you have in the market, know that one size will certainly not fit all. So it is important to have a specific product in mind.</li>
                            <li><b>Consider a specific stage of your journey:</b> I elaborate more below, but suffice it to say, journey maps are rarely valuable when they are extremely broad. Narrowing the scope to a certain scenario is more likely to yield the results you are looking for. </li>
                        </ul>
                        <h3>Spine and Stages</h3>
                        <p className="pt-3 pb-1">Most companies benefit from having a common “spine”. I think of the spine as the primary set of steps our customers take during their relationship with us. There are many different perspectives on a spine. Here are a couple of examples from <a href="https://www.mckinsey.com/business-functions/marketing-and-sales/our-insights/the-consumer-decision-journey#">McKinsey</a> and <a href="https://www.gartner.com/en/marketing/insights/articles/how-to-create-an-effective-customer-Journey-map">Gartner</a>.</p>
                        <Image className="py-3" src="/assets/images/mckinsey-journeymap.png" alt="Journey Map from McKinsey" fluid/>
                        <Image className="py-3" src="/assets/images/gartner_journeymap.png" alt="Journey Map from Gartner" fluid/>
                        <p className="py-1">Once you have your spine you will need to think about the stages. These are specific to your persona and your scenario. So for example, if you exploring “onboarding” for a specific product and the system administrator persona, your stages might be technical implementation, setting up pilot environment, setting up users etc. Once you have your spine and your stages, you are ready for the best part of the exercise - action planning. </p>
                        <h3>Action Planning</h3>
                        <p className="pt-3 pb-1">Action planning is the “why” behind journey mapping. Driving action and improvements should literally be the <b>only reason</b> why you undertake the effort of creating a journey map. Remember the destination we were discussing earlier? That of an improved customer experience? Well, once you are armed with a clear set of objectives, scope, spine and stages you are ready to chart your course to that destination. </p>
                        <ul className="pb-3">
                            <li><b>Wants and Needs:</b> This is the first step in understanding your customers. What do they need to accomplish in this specific stage? How are they feeling about their ability to accomplish those tasks? These are questions you must answer.</li>
                            <li><b>Touchpoints:</b> Next you list all the points of interaction between the customer and your brand, product or personnel. These are your touchpoints.</li>
                            <li><b>Moments of Truth and Painpoints:</b> Finally you think through which of those touchpoints make or break the customer’s relationship with you. Those are your moments of truth. Using our previous example, if a sysadmin was unable to onboard users during the onboarding stage, that would be a pretty dire circumstance, right? Thus, being able to onboard users becomes a moment of truth. The last step in the journey is to identify where the painpoints are within your touchpoints. </li>
                        </ul>
                        <p className="pt-3 pb-1">And if you happen to see that any of your moments of truth are a current painpoint, you have found your first initiative! You now know that fixing that painpoint will improve customer experience, thus getting you to your destination. </p>
                        <p className="py-1"><em>“The journey, not the destination matters…”</em> ~ T.S. Eliot. Just remember, building a journey map will yield absolutely no results unless you actively take action based on what you learn from the journey. </p>
                        <hr />
                        <h5>Cited:</h5> 
                        <ul className="pb-3">
                            <li><a href="https://www.mckinsey.com/business-functions/marketing-and-sales/our-insights/the-consumer-decision-journey#">The consumer decision journey</a></li>
                            <li><a href="https://www.gartner.com/en/marketing/insights/articles/how-to-create-an-effective-customer-Journey-map">How to Create an Effective Customer Journey Map</a></li>
                        </ul>
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default JourneyMapping;
