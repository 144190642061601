import React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const Subscribe = () => {
    
    return (
        <div className="subscribecard px-3 mb-4">
            <div id="mc_embed_signup">
                <Form action="https://nextbonnie.us7.list-manage.com/subscribe/post?u=df4aea66feabb8257748e4704&amp;id=cdcca58af1" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                    <div id="mc_embed_signup_scroll">
                    <h5 className="py-4">Get the latest via email</h5>
                    <Form.Group className="mc-field-group">
                        <Form.Control className="required email" id="mce-Email" type="email" placeholder="name@example.com" name="EMAIL" />
                    </Form.Group>
                        <div id="mce-responses" className="clear">
                            <div className="response hidden" id="mce-error-response"></div>
                            <div className="response hidden" id="mce-success-response"></div>
                        </div>    
                        {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                        <div className="hideleft" aria-hidden="true"><input type="text" name="b_df4aea66feabb8257748e4704_cdcca58af1" tabindex="-1" value="" />

                        </div>
                        <div className="clear pb-3">
                            <Button className="subscribebutton" id="mc-embedded-subscribe" value="Subscribe" name="subscribe" type="submit">Subscribe</Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Subscribe;