import React from 'react';
import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const TopNav = () => {
    let history = useHistory();

    const handleSelect = (event) => {
        history.push(`/${event}`);
    }
    
    return (
        <Navbar className="blognav pt-4 mb-5" bg="light" variant="light">
            <Container>    
                <Navbar.Brand className="topnavbrand" href="/">
                    <div className="d-inline-block"><span className="blogname">Next Bonnie</span><br /><span className="tagline">Elevate your customer experience</span></div>
                </Navbar.Brand>
                <Nav className="ml-auto">
                    <Nav.Link eventKey="blog" onSelect={(event) => handleSelect(event)}>BLOG</Nav.Link>
                    <Nav.Link eventKey="about" onSelect={(event) => handleSelect(event)}>ABOUT ME</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}

export default TopNav;