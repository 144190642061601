import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const DiversityInclusion = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Diversity and Inclusion in CX</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>Diversity and Inclusion in Customer Experience</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">April 6, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/atomic-habits"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/diversity-inclusion&text=Diversity+and+Inclusion+in+CX&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">I know that many of you are focused on building inclusive environments for your companies, teams and within your social circles. Are you also focused on building an inclusive CX practice? Do you know how to do so?</p>
                        <p className="py-1">There are many frameworks available that are designed to help leaders and teams build an inclusive environment. The approach I like has 3 main areas of focus - practice awareness, exercise curiosity, demonstrate courage and each of these can be translated to a CX discipline.</p>
                        <Image className="py-3" src="/assets/images/diandb1.jpeg" alt="Diverstiy and Inclusion" fluid/>
                        <h2>Practice Awareness</h2>
                        <p className="pt-3 pb-1">Our starting point needs to be one of building our knowledge and our awareness. We need to understand the situation and the problems before we can attempt to solve them. </p>
                        <h4>Examine your ASSUMPTIONS</h4>
                        <p className="py-1">We make assumptions all the time because our brains are biased and ever ready to put together random bits of unrelated information to create a narrative. Often, this narrative is false. So as a CX leader, it is important to take a step back and introspect on what assumptions we are holding about our customers.</p>
                        <ul className="pb-3">
                            <li>Are you correct about our demographics? </li>
                            <li>Are you cognizant of their motivations? </li>
                            <li>Do you know how your product impacts their lives?</li>
                        </ul>
                        <h4>If you have a STRONG REACTION to someone, ask yourself why</h4>
                        <p className="py-1">Our brains have evolved to try to protect us from all situations. Most of the time our strong reactions - like hating the smell of spoilt milk - is meant to protect us. So, as a CX leader, if you are having some strong reactions to your customers and their feedback, it is time to take a moment to understand why. </p>
                        <ul className="pb-3">
                            <li>Are you afraid that they are right?</li>
                            <li>Are you upset at being called out?</li>
                            <li>Are you feeling defensive knowing that you and your company have put their best foot forward?</li>
                        </ul>
                        <h4>Include and SEEK INPUT from people from a wide variety of backgrounds</h4>
                        <p className="py-1">Our brains are very capable of gathering information using all five of our senses at once. But as CX leaders, we have to make an actual effort to seek inputs from all our constituents. It is so important to have a holistic picture of what your customers are sharing with you.</p>
                        <ul className="pb-3">
                            <li>Do you have personas that represent your different types of customers?</li>
                            <li>Are you ensuring that your listening posts include each of these personas?</li>
                            <li>Are you including product satisfaction as well?</li>
                        </ul>
                        <p className="py-1">Once you have established awareness and baseline information about your  customers, it is time to analyze what you are learning. </p>
                        <Image className="py-3" src="/assets/images/diandb2.jpeg" alt="Diverstiy and Inclusion" fluid/>
                        <h2>Exercise Curiosity</h2>
                        <p className="pt-3 pb-1">Once we understand the extent of the challenge in front of us, we must delve into the root causes. This is our opportunity to understand both ourselves and our customers better. And to do that, we must open our hearts to curiosity.</p>
                        <h4>Make a habit of ASKING QUESTIONS</h4>
                        <p className="py-1">We often find that our customers are more than willing to share their feedback and give us their inputs. But is the information coming in what you need in order to improve their experience and your product or service? In my experience, I have found the quality of information to be better when I ask questions that are intended to get specific information that I can then act on. If you are a product company seeking to understand	how your product is being used you might want to ask:</p>
                        <ul className="pb-3">
                            <li>Did you accomplish the task you set out to do?</li>
                            <li>How easy was it to accomplish your task today?</li>
                            <li>Did our documentation provide the answers you were looking for?</li>
                        </ul>
                        <h4>LISTEN carefully until they feel understood</h4>
                        <p className="py-1">Once we ask the questions, we need to listen to what is being shared. And I mean, truly listen. As a CX leader, </p>
                        <ul className="pb-3">
                            <li>Do you read the verbatim responses that your customers are offering? </li>
                            <li>Do you meet with your customers to truly understand what their needs and wants are? </li>
                        </ul>
                        <p className="pt-3 pb-1">I find these practices highly valuable and have definitely helped me to understand my customers better. </p>
                        <h4>Understand each person’s CONTRIBUTIONS</h4>
                        <p className="py-1">To me, this one is about knowing your personas and how each one interacts with and is impacting by your product and services.</p>
                        <ul className="pb-3">
                            <li>Do you know your key personas?</li>
                            <li>Are you aware that each persona has an unique journey with your brand? Speaking in terms of B2B situations, even if they are in the same company, different personas can have very different experiences with your product or service.</li>
                            <li>Do you understand your market breakdown by person? If you have 3 key personas, do you have the data that tells you what % of each represents your customers?</li>
                        </ul>
                        <Image className="py-3" src="/assets/images/diandb3.png" alt="Diverstiy and Inclusion" fluid/>
                        <p className="py-1">Image first seen <a href="https://twitter.com/JamieMansellPhD/status/1108158917677338624">here</a>.</p>
                        <h2>Demonstrate Courage</h2>
                        <p className="pt-3 pb-1">It requires courage to take a stand and make a difference. As a CX leader, you have the ability to ask the right questions and ensure that your focus is on solving the customers’ paint points and not just the company’s bottom line.</p>
                        <h4>Ensure all VOICES are heard</h4>
                        <p className="py-1">We were talking about understanding the different personas in the previous point. Once you understand the different persona, it is important to listen to each one of them, since will will be telling you a slightly different story.  The questions that come up at this stage:</p>
                        <ul className="pb-3">
                            <li>Do each of your personas have the opportunity to share their feedback with you? </li>
                            <li>Are you collecting information from each of your different personas?</li>
                            <li>You can extend this line of thinking to your products. Are you collecting data about your customers’ interactions with your entire portfolio of products?</li>
                        </ul>
                        <h4>Address misunderstandings and resolve DISAGREEMENTS</h4>
                        <p className="py-1">With great power, comes great responsibility. Now that you know what your customers are saying, it is time to do something about this. In CX, this is called closing the loop. Some one gave you a stellar review, thank them. Some one gave you a horrible review - thank them, remember feedback is a gift, and see how you can resolve their situation.</p>
                        <ul className="pb-3">
                            <li>What is the feedback telling you about your product?</li>
                            <li>What is the feedback telling you about your operations?</li>
                            <li>What are different ways you can get back to your customers? You can choose to acknowledge that they were heard, to thank them for their feedback, to provide updates on the change that feedback brought about, etc.</li>
                        </ul>
                        <h4>Take actions to reduce STRESSFUL situations</h4>
                        <p className="py-1">One of the final steps in building an inclusive culture is to take action and implement change. Now that you know what your customers care about and are providing feedback about, it is now time to go fix those things.</p>
                        <ul className="pb-3">
                            <li> If it is an operational shortcoming, what policies or processes can you change to ensure that the issue doesn’t recur?</li>
                            <li>If it is a product feature that is highly requested, what steps can you take to introduce it to your roadmap?</li>
                            <li>How can you improve your customer’s experience, especially in the places they report the most friction?</li>
                        </ul>
                        <h4>Be BRAVE </h4>
                        <p className="py-1">It is hard to be brave and tackle issue of systematic oppression and injustice. We often don’t know where to start. It is also challenging to start a new discipline and do it right. I hope that asking yourself the questions in this article brings you a few steps closer to building inclusivity in your CX practice as well as your lives.  Bringing a spirit of inquiry and being open to various possibilities is ultimately what will help us to build inclusivity and empathy in the world.
                        </p>
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default DiversityInclusion;
