import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const MetricsTips = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>9 Tips for Measuring Customer Experience</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>9 Tips for Measuring Customer Experience</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">February 8st, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/9-tips-metrics"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/9-tips-metrics&text=9-tips-for-Measuring-Customer-Experience&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">Measurement is always top of mind for CX professionals. After all, our entire discipline rests on the foundation of quantitatively measuring customers perception, behavior and outcomes. And yet, measurements are one of the most misunderstood areas of our work. It is easy to get caught up in vanity metrics that might look good on paper, but do very little to actually improve the customer’s experience.</p>
                        <p className="py-1">So today, I teamed up with <a href="https://www.linkedin.com/in/jessicajnoble/">Jessica Noble</a>, author of <a href="https://www.amazon.com/dp/0578234815/ref=cm_sw_r_cp_apa_fabc_HZ3Y1F883RJXBV87WS8G/">The Five Customer Experience Mistakes Causing Profit Erosion</a>, founder of <a href="https://www.magneticexperiences.com/">Magnetic Experiences</a> and CX practitioner for 19 years to offer you nine practical tips to help you elevate your measurement game.</p>
                        
                        <h3>1. Speak your stakeholders’ language </h3>
                        <p className="pt-3 pb-1">First, think about your stakeholders. Who is the sponsor of CX in your organization? What do they care about the most? Is it your CEO? Your Chief Revenue Officer? Your Chief Marketing Officer? Study their strategies and KPIs and then use that information to frame your business cases.</p>
                        <p className="py-1">ROI is a common measure in CX, albeit sometimes difficult to prove directly. In your organization, it could be that EBITDA or contribution margin is the KPI executives keep an eagle eye on. For professional services it may be utilization and realized rate. It could be that your business case will provide a material improvement for cost-to-acquire new customers but consider if that a metric that your org prioritizes. If not, frame your case around cost savings, expanded sale capacity, or something your stakeholders value.</p>
                        <h3>2. Align to your customers’ journey</h3>
                        <p className="pt-3 pb-1">The primary reason we build customer journey maps is so we can understand our customers’ pain points and take actions towards solving those problems. Best practice is to measure multiple aspects at various points along the customer journey.</p>
                        <p className="py-1">What is your primary goal in each phase of your customer journey? Is each phase of your customer journey achieving its primary goal? Putting metrics in context of the customer journey gives them more meaning. </p>
                        <Image className="py-3" src="/assets/images/cjmMeasurements.png" alt="Sample CJM and Metrics" fluid/>
                        <h3>3. Map metrics to outcomes</h3>
                        <p className="pt-3 pb-1">If you don’t know where you are going, you won’t know when you get there.  In addition to KPIs, it is good practice to think about the outcomes you are aiming for. Ask yourself - what is the outcome you want? What is the behavior you are trying to change? You can use this simple framework to logically frame your thoughts and ensure you are measuring what matters. </p>
                        <h3>4. Fail fast, fail cheap</h3>
                        <p className="pt-3 pb-1">Don’t over-engineer your CX metrics and KPIs. Think big AND start small. When it comes to our customers, it is easy to get lost in the metrics. However, it might take years to get the perfect set of data to derive the perfect conclusions. And in the meantime, your sponsors might lose patience! So instead, start small. If you want to build a customer health index with 15 data points, but only three (3) are objective and actionable <b>today</b>, then start with those three (3). If you want to survey your entire population, but have the proper contact information or opt-in agreements with a subset of your customers, start there.</p>
                        <h3>5. Pick your battles</h3>
                        <p className="pt-3 pb-1">This one is related to the point above. If you’re wondering, how do I take the 15 important metrics and start with only three (3)? One parameter to use is to make your metrics meaningful (actionable)! Select metrics based on what you will be able to do with the data. If they don’t enable action, don’t spend time on them. </p>
                        <h3>6. Prioritize your most valuable customers</h3>
                        <p className="pt-3 pb-1">Most CX leaders don’t have unlimited resources, and so it becomes very important to prioritize. Start with your most profitable, most potential for growth, and/or most influential customers. This is another way to narrow down your measurements to direct your resources towards the most important ones. For example, if you have a small number of high net-worth clients, you can pick that cohort to focus on. Or if you have a single product that is the source of your highest recurring revenue, that will be a good one to start with.</p>
                        <h3>7. Celebrate with your customers</h3>
                        <p className="pt-3 pb-1">Customers want to feel heard and acknowledged. We all do! So if you have made all this effort to understand their pain points, prioritize and execute an action plan and measure the impact it is having on your customer’s experience, then it makes sense to share that with your customers. It is their feedback that likely prompted the improvements in the first place. When you do take action, make sure your customers know the improvements came from their experiences and their feedback! Jessica shared a client story where they had made tremendous improvements to their ecommerce site based on critical customer feedback, but they weren’t hearing any new customer feedback about the changes. After reaching out to customers to ask why, customers said they had no idea there had been improvements! After their first poor ecommerce experience, they skipped the site and either picked up the phone or shopped online elsewhere.</p>
                        <Image className="py-3" src="/assets/images/customer-celebration.png" alt="Customers Celebrating" fluid/>
                        <h3>8. A picture is worth a thousand words</h3>
                        <p className="pt-3 pb-1">Before delving into displaying your data in charts or graphs, ask ‘What message and what action should this KPI be communicating?’ Those insights can guide how you represent the information. Consider your audience and the level of detail that would be most meaningful to them, then zoom in or out to show that level of detail as the starting point. This reduces the likelihood of information overload; ideally you have drill down capabilities from there. A quick, informal a/b test is a great way to find out if your visuals are making the message and desired action clear.</p>
                        <h3>9. Focus on change over time</h3>
                        <p className="pt-3 pb-1">Whenever possible, use relative metrics rather than absolute ones to track the rate of change over time. Absolute values will tell a skewed story.</p>
                         <p className="py-1">In summary, we would like to reiterate the importance of action. Ultimately, it is less about the actual metrics you're tracking, or the method is which you're messaging or displaying those metrics. The most critical thing is to take action based on what you learn from those metrics. </p>
                         
                         <h5>A little more about my co-author today:</h5> 
                        <ul className="pb-3">
                            <li><Image className="py-3" src="/assets/images/JessicaNoble.jpg" alt="Jessica Noble" fluid/></li>
                            <li>Jessica Noble</li>
                            <li>CEO</li>
                            <li>office: 858.859.2868</li>
                            <li>www.magneticexperiences.com</li>
                        </ul>
      
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default MetricsTips;
