import React from 'react';

//import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import SideBar from '../components/SideBar';

const CXKPIs = () => {
    
    //let history = useHistory();

    return (
        <div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} md={8} className="px-3">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Measuring Customer Experience with 10 Popular KPIs</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1>Measuring Customer Experience with 10 Popular KPIs</h1>
                        <div className="articleheader position-relative">
                            <Row className="mb-0">
                                <Col xs={8}>
                                    <p className="mb-0">February 15th, 2021</p>
                                </Col>
                                <Col xs={4} className="d-flex justify-content-end">
                                    <p className="mb-0">Share:
                                        {/* <a className="socialbutton px-3" href="https://www.linkedin.com/sharing/share-offsite/?url=https://nextbonnie.com/CX-KPIs"><i className="fab fa-linkedin"></i></a> */}
                                        <a className="socialbutton px-3" href="https://twitter.com/intent/tweet?url=https://nextbonnie.com/CX-KPIs&text=Measuring-CX-with-10-popular-KPIs&via=barnali_c"><i className="fab fa-twitter-square"></i></a>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <p className="pt-3 pb-1">I teamed up with <a href="https://www.linkedin.com/in/jessicajnoble/">Jessica Noble</a> again this week, author of <a href="https://www.amazon.com/dp/0578234815/ref=cm_sw_r_cp_apa_fabc_HZ3Y1F883RJXBV87WS8G/">The Five Customer Experience Mistakes Causing Profit Erosion</a>, founder of <a href="https://www.magneticexperiences.com/">Magnetic Experiences</a> and CX practitioner for 19 years to share more about measuring customer experience and ten of the most popular KPIs. If you missed last week's blog post, we shared <a href="https://www.nextbonnie.com/9-tips-metrics/">9 tips for CX measurements.</a></p>
                        <h3>Important Considerations before you start</h3>
                        <p className="py-1">Before we delve into some of the most popular CX metrics used to convey value, let’s highlight a few noteworthy considerations:</p>
                         <ul className="pb-3">
                            <li>How you select measures and KPIs for a B2B organization is different than how you would for B2C. In a B2B organization, there are multiple stakeholders; some may be a decision maker while others hands-on users.</li>
                            <li>The measures you choose should reflect whatever is essential to the goal your org is trying to achieve and define what success means.</li>
                            <li>There is no one-size-fits-all list of CX measures that perfectly apply to every business or every industry. Wouldn’t that be nice!</li>
                            <li>Which metrics you ultimately choose is not as important as having a single and unified set of CX metrics that span touchpoints and journeys. They must also ladder up to your topline CX and business KPIs. The linkage between operational measures and strategic ones should be clear. It matters less which metric(s) you use and more on your rigor around collection, analysis, and action. Driving improvement is what is important!</li>
                            <li>There is NO getting around the hard work of digging into your measures to identify cause and effect. If you look into what you are measuring today, they are likely ALL lagging indicators, meaning they measure current performance. Lagging indicators are easy to measure but difficult to change because they represent history. The magical, valuable, morsels of insight that can light your path to improvement are leading indicators. They show you HOW to produce desired results. They are difficult to identify, difficult to measure, dynamic and vary from industry to industry and company to company. When we said cause and effect, if a fishbone diagram popped into your head, you’re thinking along the right lines to begin your cause-and-effect analysis.</li>  
                        </ul>
                        <Image className="py-3" src="/assets/images/KPI1.png" alt="" fluid/>
                        <h3>Ten Popular Customer Experience (CX) Measures</h3>
                        <p className="pt-3 pb-1">One last thing to consider before we go through some of the most popular CX measures, how many of the following measures capture customer perception? Since perception is at the heart of customer experience, that seems important!</p>
                        <h3>1. Net Promoter Score (NPS)</h3>
                        <p className="pt-3 pb-1">A widely adopted metric used to determine if customers are advocates for a business. It is calculated as a percentage of customers rating their likelihood to recommend a company, product or service as 9 or 10 (promoters) minus the percentage rating at 6 or below (detractors) on a scale from 0 to 10.
This is not the topic to raise at a light-hearted, celebratory dinner for CX professionals unless you are prepared for a lively debate as to its merits and value! </p>
                         <p className="pt-3 pb-1">Rather than delving into the pros and cons, we’ll just include a few tips:</p>
                        <ul className="pb-3">
                            <li>Always ask a secondary question to learn more about why a customer gave the score they did; otherwise, your score is not actionable.</li>
                            <li>If you are in a B2B org, you may not have a sample size large enough for a meaningful score. Consider calculating the mean for NPS versus dividing the scale.</li>
                        </ul>
                        <h3>2. Customer Satisfaction Survey (CSAT)</h3>
                        <p className="pt-3 pb-1">A metric used to determine how well companies are meeting customers’ expectations and evaluate how satisfied they are with the current service they receive. This is typically used at the point of a transaction (e.g., support case resolved, shipment received, service completed). A variation of this measure is overall satisfaction (OSAT).  </p>
                        <h3>3. Customer Effort Score (CES)</h3>
                        <p className="pt-3 pb-1">A metric used to determine how easy it is to do business with a company. Did you know ease of doing business is the strongest correlation with customer loyalty and customer satisfaction! A combination of OSAT and CES tied to operational metrics can be a great alternative to NPS for B2B orgs. (Jessica’s personal favorite!)</p>
                        <p className="py-1">"94% of customers who have a low-effort experience express intent to repurchase. Compare that with customers who had a high-effort experience — only 4% expressed intent to buy again."  - Gartner</p>
                        <h3>4. Customer Acquisition Cost (CAC) and New Customer Acquisition Metric (NCA)</h3>
                        <p className="pt-3 pb-1">CAC  measures how much money a company has to spend in order to earn a new customer. NCA provides insights into the return on investment for acquiring new customers and is calculated as CAC divided by the number of new customers.</p>
                        <h3>5. Cost to Serve</h3>
                        <p className="pt-3 pb-1">Cost analysis of all cost factors to service a customer, enabling you to calculate customer profitability. Do you know which customers cost you the most to serve? This information is critical for your growth strategy and to avoid profit margin erosion.</p>
                         <Image className="py-3" src="/assets/images/KPI2.png" alt="" fluid/>
                        <h3>6. Customer Lifetime Value (CLV)</h3>
                        <p className="pt-3 pb-1">Customer lifetime value is the total worth of a customer to a business over the whole period of their relationship. It is an important metric as it costs less to keep existing customers than it does to acquire new ones. Increasing the value of your existing customers is a great way to drive growth. Knowing the CLV helps businesses develop strategies to acquire new customers and retain existing ones while maintaining profit margins. CLV is a great metric to use when you have a multi-year relationship with a customer, and it is good for spotting early signs of customer churn and changing trends.</p>
                        <h3>7. Share of Wallet</h3>
                        <p className="pt-3 pb-1">The Wallet Allocation Rule is a method for linking brand perception and share of wallet; share of wallet is the percentage of spending in a category that goes to a particular brand. Traditional metrics like satisfaction and Net Promoter Scores correlate poorly with what matters most for many businesses: share of wallet. To learn more, check out the New York Times Best Seller <a href="https://www.walletrule.com/">Wallet Allocation Rule - Winning the Battle for Share</a>.</p>
                        <h3>8. Customer Retention and Churn Metrics</h3>
                        <p className="pt-3 pb-1">Churn metrics measure the number of customers lost over a period of time divided by the starting number of customers for that period. For some companies, you need to get started by defining what an active customer is versus a lost one. Ultimately, we are all striving to improve our customer retention, and so this is an important area to keep an eye on.</p>
                        <h3>9. Referral Metric</h3>
                        <p className="pt-3 pb-1">Rate of referral by customer type is an important insight to maximize your revenue stream. This metric makes it possible to identify trends in how referrals may differ by customer type and over time.</p>
                        <h3>10. Customer Revenue and Profitability Metrics</h3>
                        <p className="pt-3 pb-1">Profitability metrics by customer type makes it possible to focus on growing your most profitable customers and shrinking the costlier ones.</p>
                        <p className="py-1">Bonus - a few other popular measures include upsell, cross-sell, average order value, deal win/loss rate, and conversion rate.
How many of the above measures are capturing customer perception? How many of the above metrics reflect what matters most to customers? If you thought something along the lines of barely one or two of them, you are already thinking like a customer!</p>
                        <h3>Questions to noodle on</h3>
                        <p className="pt-3 pb-1">As you are determining which KPIs represent your business the best, here are some additional questions to ask yourself:</p>
                        <ul className="pb-3">
                            <li>Which of your metrics are relational/strategic versus transactional/operational  (e.g., average time to resolution, first contact resolution)?</li>
                            <li>Can you slice and dice your data by customer type? By channel of engagement?</li>
                            <li>Are your metrics actionable? Directional? Quantitative?</li>
                            <li>Which of your metrics are leading indicators versus lagging ones?</li>
                            <li>Do you have metrics that reflect the end-to-end journey? Each stage of the journey? Individual touchpoints?</li>  
                        </ul>
                        <h5>A little more about my co-author today:</h5> 
                        <ul className="pb-3">
                            <li><Image className="py-3" src="/assets/images/JessicaNoble.jpg" alt="Jessica Noble" fluid/></li>
                            <li>Jessica Noble</li>
                            <li>CEO</li>
                            <li>office: 858.859.2868</li>
                            <li>www.magneticexperiences.com</li>
                        </ul>
                      
                    </Col>
                    <SideBar />
                </Row>
            </Container>
        </div>

    )
}

export default CXKPIs;
